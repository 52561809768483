.containerWelcomePage {
  display: flex;
  position: relative;
  flex-direction: column;
  text-align: center;
  background: #0e627d;
  color: white;
  justify-content: space-between;
  padding: 40px 40px;
}

.yellow {
  color: #f8ea1d;
}

.hr {
  width: 30%;
  text-align: left;
  margin-left: 0;
  border: 2px solid #f8ea1d;
}

.containerLogo {
  max-width: 80%;
  height: 30vh;
  margin: auto;
}

.containerImg {
  display: flex;
  flex-direction: column;
}

.containerBtnInp {
  display: flex;
  flex-direction: column;
}

.containerText {
  height: 25%;
  display: flex;
  flex-direction: column;
  text-align: left;
  margin: auto;
}

.bigText {
  font-size: 4vh;
  font-weight: bold;
}

.smallText {
  font-size: 2.5vh;
  font-weight: bold;
}

.HP_Button {
  width: fit-content;
  padding: 5px 40px;
  margin-top: 5px;
  font-weight: bold;
  font-size: large;
  align-self: center;
  vertical-align: middle;
  border: 3px solid black;
}

.inputText {
  margin-bottom: 5px;
}

.inputHomePage {
  font-size: medium;
  padding: 5px 5px;
  margin-right: 5px;
  border: 3px solid black;
}

.qrCode {
  display: flex;
  justify-content: center;
  align-items: center;
}

.qrButton {
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px 2px;
  border: 3px solid black;
}
