.quizContainer {
    display: flex;
    overflow: auto;
    height: 100%;
}

.quizContainer ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.quizQuestionsContainer {
    /* display: flex; */
    overflow: auto;
    height: 100%;
    width: 100%;
}

.quizQuestionsContainer ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.AnsweredItems {
    border: 5px solid #F8EA1D;
    background-color: white;
    direction: none;
    padding: 3% 3%;
    margin: 4%;
    justify-content: space-between;
}

.goodAnswerItem {
    border: 5px solid #F8EA1D;
    background-color: #F8EA1D;
    direction: none;
    padding: 3% 3%;
    margin: 4%;
    justify-content: space-between;
}

.badAnswerItem {
    border: 5px solid red;
    background-color: red;
    direction: none;
    padding: 3% 3%;
    margin: 4%;
    justify-content: space-between;
}

.topBarQUiz {
    position: relative;
    top: 0;
    left: 0;
}

.topBarQuestions {
    position: relative;
    top: 0;
    left: 0;
}

.quizImage {
    width: 100vw;
    position: relative;
    top: 0;
    left: 0;
}

.quizSign {
    position: absolute;
    top: 50%;
    left: -2px;
}

.categorySign {
    position: relative;
    top: 20px;
    left: 0;
}

.categorySignRight {
    position: relative;
    padding-top: 20px;
    left: 25%;
}

.upperItemQuizContent {
    font-size: x-large;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center
}

.upperItemQuizContentBad {
    font-size: x-large;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #E6F4F8
}

.upperItemQuestionNumber {
    font-size: large;
    font-weight: bold;
    display: flex;
    justify-content: left;
    align-items: center
}

.bottomItemQuizContent {
    font-size: small;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.quizKatText {
    display: flex;
    font-weight: bolder;
    justify-content: space-between;
    color: white;
    padding: 18px 20px 0px 20px;
}

.quizCountQuestion {
    font-weight: bolder;
    color: #F8EA1D;
    padding: 18px 20px 0px 20px;
}

.singleQuizCategoriesItem {
    border: 5px solid #F8EA1D;
    background-color: #E6F4F8;
    direction: none;
    padding: 3% 3%;
    margin: 4%;
    justify-content: space-between;
}

.singleQuizQuestionItem {
    border: 5px solid #F8EA1D;
    background-color: #E6F4F8;
    direction: none;
    padding: 3% 3%;
    margin: 4%;
    justify-content: space-between;
}

.singleQuizCategoriesItemPass {
    border: 5px solid #F8EA1D;
    background-color: #F8EA1D;
    direction: none;
    padding: 3% 3%;
    margin: 4%;
    justify-content: space-between;
}

.checkQuizCategories {
    width: 6%;
    height: 6%;
    padding-right: 10px;
    align-self: left;
}