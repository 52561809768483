.menuContainer {
    justify-content: space-between;
    height: calc(100% - 58px);
    width: 100%;
}

.menuButton {
    width: fit-content;
    padding: 5px 40px;
    margin-top: 5px;
    font-weight: bold;
    font-size: large;
    align-self: center;
    vertical-align: middle;
    border: 3px solid black;
}

.menuTopBar {
    height: 80px;
    display: flex;
    justify-content: left;
    align-items: center;
    background-color: #0e627dff;
}

.menuTopBar img {
    position: absolute;
    left: 30px;
    top: 10px;
    width: 100px;
    z-index: 2;
}

.menuFOTD {
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
}

.menuList {
    display: flex;
    overflow: auto;
    height: calc(100% - 80px);
}

.singleMenuItem {
    border: 5px solid #F8EA1D;
    background-color: #E6F4F8;
    direction: none;
    padding: 3% 3%;
    margin: 4%;
    justify-content: space-between;
}

.menuTextDecoration {
    text-decoration: none;
    color: black;
}

.menuList ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.upperItemMenuContent {
    font-size: large;
    font-weight: bold;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.itemName {
    width: 80%;
}

.nextPageButton {
    width: 3%;
}

.topBarMenu {
    position: relative;
    top: 0;
    left: 0;
}

.faktOfTheDaySign {
    position: absolute;
    top: calc(50% - 22.5vw);
    right: 0px;
    width: 45vw
}

.faktOfTheDayGradient {
    position: absolute;
    display: flex;
    justify-content: space-around;
    width: 100%;
    bottom: 0px;
}

span.faktOfTheDayGradient {
    width: 80vw;
    text-align: center;
    color: #E6F4F8;
    font-size: medium;
    font-weight: bold;
    padding-bottom: 10px;
    padding-left: 10px;
}

.faktOfTheDayNextButton {
    position: absolute;
    width: 5vh;
    right: 3vh;
    padding-bottom: 10px;
}

/* Menüpunkt "Aktionstag" auf der Startseite ausblenden - Wunsch der H2F */
li.singleMenuItem:nth-child(3) {
    display: none;
}
