.scannerContainer {
    position: relative;
    text-align: center;
    background: #0E627D;
    color: white;
    border: 3px solid #F8EA1D;
    padding: 20px 0px;
    height: 70%;
    width: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.hintScannerContainer {
    background: #0E627D;
    border: 3px solid #F8EA1D;
    border-width: 3px 3px 0 3px;
    padding: 20px 0px 20px;
    text-align: center;
    height: 10%;
    width: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #F8EA1D;
    font-size: large;
    margin-bottom: -3px;
}

.insideScanner {
    width: 60%;
    position: absolute;
    /* left: 25%; */
    top: 50%;
    padding: auto;
    margin: auto;
    /* transform: translateX(-50%); */
    transform: translateY(-50%);
}

.tooltip {
    width: 80%;
}