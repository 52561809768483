.container {
  display: flex;
  margin: auto;
  position: relative;
  flex-direction: column;
  text-align: center;
  background: #0e627d;
  color: white;
  justify-content: space-between;
  border: 3px solid #f8ea1d;
  padding: 40px 50px;
  height: 80vh;
  width: 60vw;
}

.containerWithMargin {
  display: flex;
  position: relative;
  top: 25px;
  flex-direction: column;
  margin: 40px 0px;
  text-align: center;
  background: #0e627d;
  color: white;
  justify-content: space-between;
  border: 5px solid #f8ea1d;
  padding: 40px 50px;
  height: 80%;
  width: 60%;
}
