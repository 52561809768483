@media only screen and (orientation: landscape) {
    .outerContainer {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        align-items: center;
        height: 100%;
        width: 100vw;
        background-image: url("../Themes/Assets/Images/HomePageBackground.png");
        background-position: center;
        background-repeat: repeat;
    }
    .menuFotter img {
        width: 25vw;
    }
    .menuDiv {
        vertical-align: top;
        display: inline-block;
        text-align: center;
        justify-content: center;
        align-items: center;
        padding-top: 10px;
        background-color: #0e627dff;
        width: 25vw;
        height: 58px;
    }
}

@media only screen and (orientation: portrait) {
    .outerContainer {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        align-items: center;
        height: 100vh;
        width: 100vw;
        background-image: url("../Themes/Assets/Images/HomePageBackground.png");
        background-position: center;
        background-repeat: repeat;
    }
    .menuFotter img {
        width: 25vw;
    }
    .menuDiv {
        vertical-align: top;
        display: inline-block;
        text-align: center;
        justify-content: center;
        align-items: center;
        padding-top: 10px;
        background-color: #0e627dff;
        width: 25vw;
        height: 58px;
    }
}

.outerContainerDesktop {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100vw;
    background-image: url("../Themes/Assets/Images/HomePageBackground.png");
    background-position: center;
    background-repeat: repeat;
}

.outerContainerKeyboard {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
    height: 100vh;
    width: 100vw;
    background-image: url("../Themes/Assets/Images/HomePageBackground.png");
    background-position: center;
    background-repeat: repeat;
}

.outerContainerKeyboardQr {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    background-image: url("../Themes/Assets/Images/HomePageBackground.png");
    background-position: center;
    background-repeat: repeat;
}

.menuFotter {
    /* bottom: -20px; */
    display: flex;
    justify-content: center;
    align-items: center;
}

.caption {
    display: block;
    font-size: x-small;
    color: #e6f4f8;
    font-weight: bolder;
}