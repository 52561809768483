.factContainer {
    justify-content: space-between;
    height: calc(100% - 58px);
    width: 100%;
}

.factItem {
    border: 5px solid #F8EA1D;
    background-color: #E6F4F8;
    direction: none;
    padding: 3% 3%;
    margin: 5% 5%;
    justify-content: space-between;
}

.factFOTD {
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
}

.factArrowRight {
    position: absolute;
    top: 50%;
    right: 10px;
}

.factArrowLeft {
    position: absolute;
    top: 50%;
    left: 10px;
    transform: rotate(180deg);
}

.upperItemFact {
    font-size: large;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center
}

.factContent {
    display: flex;
    overflow: auto;
    height: calc(100% - 80px);
}

.factContent ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.factTopBar {
    height: 80px;
    display: flex;
    justify-content: left;
    align-items: center;
    background-color: #0e627dff;
}

.factTopBar img {
    position: absolute;
    left: 30px;
    top: 10px;
    width: 100px;
    z-index: 2;
}